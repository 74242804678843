import React from "react";

import Layout from "../../components/layout";
import {GettingStartedSidebar as Sidebar} from "../../components/sidebar";
import {GettingStartedBreadcrumb as Breadcrumb} from "../../components/breadcrumb";
import {AskHowToInfoAlert} from "../../components/alert";
import {ApiLink} from "../../components/link";
import {ExampleCodeSnippet} from "../../components/snippet";

const pageTitle = "Error Handling";

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<Sidebar/>}
            mobileNav={<Breadcrumb activeName={pageTitle}/>}
    >
        <p>
            When an error occurs inside SQLAPI++ library it throws an
            exception of type {' '}<ApiLink>SAException</ApiLink>.
            The recommended way to process errors is to catch a reference
            to {' '}<ApiLink>SAException</ApiLink>{' '} class.
        </p>

        <p>
            The following complete example shows a common pattern for handling library exceptions:
        </p>
        <ExampleCodeSnippet filename="step1.cpp"/>

        <AskHowToInfoAlert/>
    </Layout>
);

export default Page;
